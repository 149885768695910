
.formApplication__containerOfInputes {
    display: flex;
    gap: 3.5px;
    flex-direction: column;
}
.formApplication__containerBorder {
  position: relative;
}
.formApplication__textContainer{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
}
.formApplication__border::placeholder{
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    color: #3232324D;
    font-family: Montserrat;
}
.formApplication__border:focus-visible {
    outline: 0.01px solid #2689E9E3;
}
.formApplication__textarea {
    padding-top: 15px;
    font-family: Montserrat;
    display: flex;
    align-items: center;
    height: 75px;
    border-radius: 20px;
    border: 2px solid #2689E9E3;
    padding-left: 15px;
    resize: none;
}
.formApplication__textarea:focus-visible {
  outline: 0.01px solid #2689E9E3;
}
.formApplication__containerTextarea {
    position: relative;
}
.formApplication__textarea::placeholder {
    position: absolute;
    top: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    color: #3232324D;
    font-family: Montserrat;
}
.formApplication__buttonContainer {
    padding-top: 10px;
}
.formApplication__button {
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
}
.formApplication__button:hover {
    background: none;
    color: #2689E9;
    border: solid 1px #2689E9;
}
.formApplication__button:active {
    background: #2689E9;
    color: white;
}
.formApplication__policy {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formApplication__textPolicy {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
}
.formApplication__textPolicySecond {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    text-align: center;
    color: #2689E9;
    text-decoration: underline;
}
.formApplication__policyContainer {
    display: flex;
    gap: 5px;
}
@media (min-width: 1170px) {
    .formApplication__image_1024,
    .treadmill_430px,
    .formApplication__image_430,
    .formApplication__containerView_430,
    .form-media_hide,
    .form-media,
    .header430px {
      display: none;
    }
    .formApplication {
        padding-top: 100px;
        padding-left: 40px;
        padding-right: 40px;
      }
     
      .formApplication__container {
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 30px;
      }
      .formApplication__secondTitle {
        padding-top: 35px;
        padding-left: 50px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
    }
    .formApplication__secondTitle_paragraph {
        padding-top: 15px;
        padding-left: 50px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
    }
      .formApplication__mainTitle {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        color: #2689E9;
        font-size: 48px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
      }
      .formApplication__containerView {
        position: relative;
        display: flex;
      }
      .formApplication__containerImage {
        display: flex;
      }
      .formApplication__image {
        position: relative;
      }
      .formApplication__image_1440 {
        position: absolute;
        left: -365px;
        top: 0px;
        z-index: 1;
      }
      .formApplication__title {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        z-index: 2;
      }
      .formApplication__formContainer {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-right: 30px;
        gap: 10px;
        align-items: center;
        padding-bottom: 29px;
      }
      .formApplication__border {
        font-family: Montserrat;
        display: flex;
        align-items: center;
        border: 2px solid #2689E9E3;
        width: 204px;
        height: 45px;
        border-radius: 33px;
        padding: 0;
        padding-left: 15px;      
      }
      .formApplication__input {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .formApplication__textarea {
        width: 204px;
      }
      .formApplication__button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 12px 82px;
        background: #2689E9;
        color: white;
        border: solid 1px #2689E9;
        border-radius: 100px;
        font-weight: 700;
        font-size: 16px;
        line-height: 35px;
        width: 225px;
        height: 52px;
      }
      .formApplication__icons {
        display: flex;
        padding-top: 145px;
        padding-left: 50px;
        gap: 15px;
      }
      .formApplication__error_noName {
        position: absolute;
        top: 50px;
        left: 20px;
        font-size: 10px;
        font-weight: 500;
        color: #CA5757;
        background-color: #fff;
    }
    .formApplication__error_noName::before {
        content: url("../../public/images/error_form.svg");
        position: absolute;
        top: -35px;
        left: 210px;
    }
    .formApplication__error_okName::before {
        content: url("../../public/images/ok_form.svg");
        position: absolute;
        top: 15px;
        right: -27px;
    }
    .formApplication__error_noContact {
        position: absolute;
        top: 50px;
        left: 20px;
        font-size: 10px;
        font-weight: 500;
        color: #CA5757;
        background-color: #fff;
    }
    .formApplication__error_noContact::before {
        content: url("../../public/images/error_form.svg");
        position: absolute;
        top: -35px;
        left: 210px;
    }
    .formApplication__error_okContact::before {
        content: url("../../public/images/ok_form.svg");
        position: absolute;
        top: 15px;
        right: -27px;
    }
    .formApplication__error_noEmail {
      position: absolute;
      top: 50px;
      left: 20px;
      font-size: 10px;
      font-weight: 500;
      color: #CA5757;
      background-color: #fff; 
    }
    .formApplication__error_noEmail::before {
      content: url("../../public/images/error_form.svg");
      position: absolute;
      top: -35px;
      left: 210px;
    } 
    .formApplication__error_okEmail::before {
      content: url("../../public/images/ok_form.svg");
      position: absolute;
      top: 15px;
      right: -27px;
    }
}
@media (max-width: 1169px) {
  .formApplication__image_1440,
  .treadmill_430px,
  .formApplication__image_430,
  .formApplication__containerView_430,
  .form-media_hide,
  .form-media,
  .header430px {
    display: none;
  }
  .formApplication {
      padding-top: 49px;
      padding-left: 40px;
      padding-right: 40px;
    }
    .formApplication__containerView {
      position: relative;
      display: flex;
    }
    .formApplication__container {
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      border-radius: 30px;
      position: relative;
    }
    .formApplication__mainTitle {
      display: flex;
      flex-wrap: wrap;
      padding-top: 25px;
      padding-left: 40px;
      padding-right: 50px;
      color: #2689E9;
      font-size: 48px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0em;
    }
    .formApplication__secondTitle {
      padding-top: 35px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
  }
  .formApplication__secondTitle_paragraph {
      padding-top: 15px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      z-index: 10;
  }
    .formApplication__containerImage {
      display: flex;
    }
    .formApplication__image {
      position: relative;
    }
    /* .formApplication__image_1440 {
      background-color: coral;
      position: absolute;
      left: -435px;
      top: -38px;
      z-index: 1;
    } */
    .formApplication__image_1024 {
      position: absolute;
      left: -270px;
      bottom: 0px;
      z-index: 1;
    }
    .formApplication__title {
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
    }
    .formApplication__formContainer {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-right: 20px;
      gap: 10px;
      align-items: center;
      padding-bottom: 29px;
    }
    .formApplication__border {
      font-family: Montserrat;
      display: flex;
      align-items: center;
      border: 2px solid #2689E9E3;
      width: 204px;
      height: 45px;
      border-radius: 33px;
      padding: 0;
      padding-left: 15px;    
    }
    .formApplication__input {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .formApplication__textarea {
      width: 204px;
    }
    .formApplication__button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 12px 82px;
      background: #2689E9;
      color: white;
      border: solid 1px #2689E9;
      border-radius: 100px;
      font-weight: 700;
      font-size: 16px;
      line-height: 35px;
      width: 225px;
      height: 52px;
    }
    .formApplication__icons {
      display: flex;
      padding-top: 150px;
      padding-left: 50px;
      gap: 15px;
    }
    .formApplication__error_noName {
      position: absolute;
      top: 50px;
      left: 20px;
      font-size: 10px;
      font-weight: 500;
      color: #CA5757;
      background-color: #fff;
  }
  .formApplication__error_noName::before {
      content: url("../../public/images/error_form.svg");
      position: absolute;
      top: -35px;
      left: 210px;
  }
  .formApplication__error_okName::before {
      content: url("../../public/images/ok_form.svg");
      position: absolute;
      top: 15px;
      right: -27px;
  }
  .formApplication__error_noContact {
      position: absolute;
      top: 50px;
      left: 20px;
      font-size: 10px;
      font-weight: 500;
      color: #CA5757;
      background-color: #fff;
  }
  .formApplication__error_noContact::before {
      content: url("../../public/images/error_form.svg");
      position: absolute;
      top: -35px;
      left: 210px;
  }
  .formApplication__error_okContact::before {
      content: url("../../public/images/ok_form.svg");
      position: absolute;
      top: 15px;
      right: -27px;
  }
  .formApplication__error_noEmail {
    position: absolute;
    top: 50px;
    left: 20px;
    font-size: 10px;
    font-weight: 500;
    color: #CA5757;
    background-color: #fff; 
  }
  .formApplication__error_noEmail::before {
    content: url("../../public/images/error_form.svg");
    position: absolute;
    top: -35px;
    left: 210px;
  } 
  .formApplication__error_okEmail::before {
    content: url("../../public/images/ok_form.svg");
    position: absolute;
    top: 15px;
    right: -27px;
  }
}
@media (max-width: 480px) {
    .formApplication__title,
    .formApplication__secondTitle,
    .formApplication__image,
    .formApplication__containerView,
    .formApplication__icons, 
    .form-media_hide {
      display: none;
    }
    .form-media {
      display: flex;
    }
    .formApplication {
      padding: 0px;
    }
    .form-media__cross_430px {
        position: absolute;
        right: 30px;
      }
      .form-media__title_430 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .form-media__container_430 {
        background: #FFFFFF;
        border-radius: 30px;
        width: 100%;
        position: fixed;
        top: 50px;
        animation: mymove 300ms;
        animation-timing-function: ease-in;
      }

      @keyframes mymove {
        0% {top: -580px;}
        100% {top: 50px;}
      }
      .form-media__containerOfTitle_430 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .form-media__textOfTitle_430 {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
      }
      .form-media__formApplication_430 {
        padding-top: 20px;
      }
      .formApplication__container {
        position: relative;
        display: flex;
        flex-direction: column;
        /* width: 100%; */
        background: none;
        border-radius: 30px;
        padding-bottom: 332px;
    
      }
      .formApplication__mainTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 42px;
        padding-bottom: 0px;
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
        color: #2689E9;
      }
      .formApplication__image_430 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .formApplication__imageCoupleBuilders_430 {
        max-width: 100%;
        /* height: auto; */
        position: absolute;
        bottom: 30px;
        /* display: inline-block; */
      }
      
      .formApplication__title {
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
      }
      .formApplication__containerView_430 {
        display: flex;
        /* padding-top: 20px; */
        position: relative;
        overflow: hidden;
      }
      .formApplication__container_430 {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 30px;
        padding-bottom: 10px;
        width: 100%;
      }
      .formApplication__title_430 {
        font-size: 20px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
        color: #323232;
        line-height: 1em;
      }
      .formApplication__secondTitle_430 {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        line-height: 1em;
        color:#32323299;
      }
      .formApplication__formContainer_430 {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        align-items: center;
        justify-content: center;
        padding: 32px 29px;
        gap: 20px;
      }
      .formApplication__input {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }
      .formApplication__textarea {
        width: 270px;
      }
      .formApplication__border {
        font-family: Montserrat;
        display: flex;
        border: 2px solid #2689E9E3;
        height: 45px;
        width: 270px;
        border-radius: 33px;
        padding-left: 15px;
      }
      .formApplication__formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
        padding: 0px;
      }
      .formApplication__policy {
        padding-top: 10px;
      }
      .formApplication__textPolicySecond {
        padding-bottom: 10px;
    }
      .formApplication__button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 12px 82px;
        background: #2689E9;
        color: white;
        border: solid 1px #2689E9;
        border-radius: 100px;
        font-weight: 700;
        font-size: 16px;
        line-height: 35px;
        width: 80vw;
        height: 60px;
      }
      .formApplication__error_noName {
        position: absolute;
        top: 50px;
        right: 112px;
        font-size: 10px;
        font-weight: 500;
        color: #CA5757;
        background-color: #fff;
    }
    .formApplication__error_noName::before {
        content: url("../../public/images/error_form.svg");
        position: absolute;
        top: -35px;
        left: 271px;
    }
    .formApplication__error_okName::before {
        content: url("../../public/images/ok_form.svg");
        position: absolute;
        top: 15px;
        right: -25px;
    }
    .formApplication__error_noContact {
        position: absolute;
        top: 53px;
        right: 112px;
        width: 200px;
        font-size: 10px;
        font-weight: 500;
        color: #CA5757;
        background-color: #fff;
    }
    .formApplication__error_noContact::before {
        content: url("../../public/images/error_form.svg");
        position: absolute;
        top: -38px;
        left: 272px;
    }
    .formApplication__error_okContact::before {
        content: url("../../public/images/ok_form.svg");
        position: absolute;
        top: 15px;
        right: -25px;
    }
    .formApplication__error_noEmail {
      position: absolute;
      top: 50px;
      left: 18px;
      font-size: 10px;
      font-weight: 500;
      color: #CA5757;
      background-color: #fff; 
    }
    .formApplication__error_noEmail::before {
      content: url("../../public/images/error_form.svg");
      position: absolute;
      top: -35px;
      left: 273px;
    }
    .formApplication__error_okEmail::before {
      content: url("../../public/images/ok_form.svg");
      position: absolute;
      top: 15px;
      right: -25px;
    }
}




    

