.formVidjet__background {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    animation: show 300ms;
}
@keyframes show {
    from {opacity: 0;} to {opacity: 1;}
    }  
.formVidjet__container {
    background: #FFFFFF;
    border-radius: 30px;
    display: flex;
    position: fixed;
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.formVidjet__cross {
    padding: 20px 20px;
}
.formVidjet__form {
    padding-top: 40px;
    padding-left: 40px;
    flex-shrink: 0;
}
@media (min-width: 431px) {
    .formVidjet__background_430,
    .formVidjet-media_hide {
      display: none;
    }
    .formVidjet__image {
        background-image: url(../../public/images/worker.jpg);
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        border-radius: 30px;
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
    }
    .formVidjet-media {
        display: flex;
    }
}
@media (max-width: 480px) {
    .formVidjet-media_hide,
    .formVidjet-media {
      display: none;
    }
    .formVidjet__background_430 {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 10;
        animation: show 300ms;
    }
    @keyframes show {
        from {opacity: 0;} to {opacity: 1;}
        } 
}
