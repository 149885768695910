.services__title {
    margin: 0;
    color: #F4F4F4;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 13px;
}
.services__text {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 19px;
    color: #F4F4F4CC;
}
.services__buttonOfContact {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 5px;
    background: #f4f4f4;
    color: #323232;
    width: 118px;
    height: 40.29268264770508px;
    border-radius: 28px;
    font-size: 10px;
    font-weight: 500;
    line-height: 35px;
}
@media (min-width: 1125px) {
  .services__vidjets_430 {
    display: none;
  }
  .services {
      display: flex;
      gap: 60px;
      flex-direction: column;
    }
    .services__container {
      padding-left: 40px;
      padding-right: 40px;
    }
    .services__vidjets {
      display: flex;
      gap: 27px;
    }
    .services__frame {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px 30px 0px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 33%;
      height: 230px;
      border-radius: 30px;
      background: linear-gradient(264.58deg, #266DB3 16.05%, #619BD5 85.17%);
    }
}
@media (max-width: 1124px) {
  .services {
    display: flex;
    flex-direction: column;
    /* padding-top: 35px; */
    gap: 40px;
  }
  .services__vidjets_430 {
    display: flex;
    gap: 27px;
    overflow-x: auto;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0;
  }
  .services__frame {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px;
    justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 230px;
    width: 316px;
    border-radius: 30px;
    background: linear-gradient(264.58deg, #266DB3 16.05%, #619BD5 85.17%);
  }
  .services__frame_430 {
    flex-direction: column;
    display: flex;
  }
  .services__frame:not(:last-child) {
    margin-right: 1px;
  }
}
@media (max-width: 480px) {
    .services {
        display: flex;
        flex-direction: column;
        padding-top: 0px;
        gap: 20px;
        overflow: hidden;
      }
      .services__vidjets_430 {
        display: flex;
        gap: 20px;
        overflow-x: auto;
        padding: 0;
        margin: 0;
      }
      .services__frame {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0px 20px 0px;
        width: 316px;
        height: 280px;
        border-radius: 30px;
        background: linear-gradient(264.58deg, #266DB3 16.05%, #619BD5 85.17%);
      }
      .services__frame_430 {
        flex-direction: column;
        display: flex;
      }
      .services__frame:not(:last-child) {
        margin-right: 1px;
      }
}
