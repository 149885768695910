.application__info {
  display: flex;
  flex-direction: column;
}
.application__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
.apolication__containerKeywords {
  padding-top: 60px;
}
.application__keywords {
  padding-top: 10px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.application__keyword {
  display: flex;
  font-size: 10px;
  line-height: 20px;
  border: 1px solid #6A6A6A;
  padding: 4px 8px 4px 8px;
  border-radius: 13.5px;
  gap: 10px;
}
@media (min-width: 1123px) {
  .application__keywordsLast_1024,
  .application__keywords_1024,
  .apolication__containerKeywords_1024,
  .application__button_430,
  .application__containerButton_430 {
    display: none;
  }
  .application {
    padding-top: 37px;
}
  .application__container {
      display: flex;
      justify-content: space-between;
      height: 204px;
      border-radius: 30px;
      background: #FFFFFF;
      padding: 40px 30px 0px;
      gap: 30px;
    }
    .application__text {
      padding: 15px 0px 50px;
      font-weight: 500;
      line-height: 16px;
      color: #6A6A6A;
      font-size: 14px;
      font-weight: 500;
    }
    .application__containerButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 185px;
      height: 35px;
      padding: 12px 35px;
      border-radius: 30px;
      gap: 10px;
      background: #2689E9;
      transition: all linear .3s;
      -webkit-transition: all linear .3s;
    }
    .application__button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 35px;
      color: #F4F4F4;
      text-decoration: none;
    }
    .application__containerButton:hover {
      background: none;
      color: #2689E9;
      border: solid 1px #2689E9;
      }
    .application__containerButton:active {
      background: #2689E9;
      color: white;
    }
}
@media (max-width: 1122px) {
.application__button_430,
.apolication__containerKeywords,
.application__containerButton_430 {
  display: none;
}
.application {
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}
.application__container {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    border-radius: 30px;
    background: #FFFFFF;
    padding: 40px 0px 0px;
    padding-left: 30px;
  }
  .application__text {
    padding: 15px 0px 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #6A6A6A;
  }
  .apolication__containerKeywords_1024 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
  .apolication__containerKeywordsBotttom_1024 {
    display: flex;
    flex-direction: column;
  }
  .apolication__container_1024 {
    display: flex;
    justify-content: space-between;
    gap: 70px;
  }
  .apolication__containerBottom_1024 {
    display: flex;
    justify-content: space-between;
    align-self: end;
  }
  .apolication__containerButton_1024 {
    padding-top: 10px;
    padding-bottom: 20px; 
  }
  .application__keywords_1024 {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  .application__keywordsLast_1024 {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding-top: 10px;

  }
  .application__keywords {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  .application__containerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 35px;
    padding: 12px 35px;
    border-radius: 30px;
    gap: 10px;
    background: #2689E9;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
  }
  .application__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    color: #F4F4F4;
    text-decoration: none;
  }
.application__containerButton:hover {
  background: none;
  color: #2689E9;
  border: solid 1px #2689E9;
    background: none;
    color: #2689E9;
    border: solid 1px #2689E9;
  }
  .application__containerButton:active {
    background: #2689E9;
    color: white;
  }
}
@media (max-width: 480px) {
  .application__keywordsLast_1024,
  .formApplication__secondTitle_paragraph,
  .application__keywords_1024,
  .apolication__containerKeywords_1024,
  .application__button_1440,
  /* .apolication__containerButton_1024, */
  .apolication__containerKeywords {
    display: none;
  }
  .application {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 5px;
    position: relative;
}
  .application__container {
      display: flex;
      gap: 0px;
      position: relative;
      padding: 0px;
      background: none;
    }
    .application__info {
      display: flex;
      gap: 10px;
      padding: 20px 20px;
      background: #FFFFFF;
      border-radius: 30px;
    }
    .application__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .application__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding: 0px;
    }
    .apolication__containerBottom_1024 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .application__button_430 {
      padding-top: 20px;
      /* padding-left: 35px; */
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .apolication__containerButton_1024 {
      padding-top: 0px;
      padding-bottom: 30px;
    }
    .application__containerButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60vw;
      height: 35px;
      padding: 12px 35px;
      border-radius: 30px;
      gap: 10px;
      background: #2689E9;
    }
    .application__button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
      color: #F4F4F4;
    }
}
