
.thankYouPage__crossImage {
    z-index: 10;
}
.thankYouPage__containerMessage {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.thankYouPage__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
}
.thankYouPage__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #32323299;
}
.thankYouPage__containerOk {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
@media (min-width: 851px) {
    .thankYouPage__container {
        display: flex;
        flex-direction: row-reverse;
        background-color:#FFFFFF;
        border-radius: 30px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        padding-left: 80px;
        padding-right: 30px;
    }
    .thankYouPage__crossImage {
        padding-top: 30px;
        padding-left: 40px;
    }
    .thankYouPage__ok {
        display: flex;
        justify-content: center;
        padding-top: 65px;
    }
    .thankYouPage__logoOfContact {
        padding-top: 30px;
        padding-bottom: 70px;
        display: flex;
        justify-content: center;
        gap: 30px;
    }
}
@media (max-width: 480px) {
    .thankYouPage__container {
        background-color:#FFFFFF;
        border-radius: 30px;
        position: fixed;
        z-index: 5;
        left: 45px;
        width: 80%;
        position: fixed;
        top: 50px;
        animation: mymove 300ms;
        animation-timing-function: ease-in;
      }
      @keyframes mymove {
        0% {bottom: 50px;}
        100% {bottom: -580px;}
      }
    
    .thankYouPage__cross {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        padding-right: 30px;
    }
    .thankYouPage__ok {
        display: flex;
        justify-content: center;
        padding-top: 75px;
    }
    .thankYouPage__containerMessage {
        padding-top: 76px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .thankYouPage__logoOfContact {
        padding-top: 195px;
        padding-bottom: 77px;
        display: flex;
        justify-content: center;
        gap: 51px;
    }
}