
.about__iconsItem {
    display: flex;
    gap: 28px;
    align-items: center;
}
.about__iconsText {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
@media (min-width: 1040px) {
  .about__icons_430,
  .about__containerIcons_1024,
  .about__text_430 {
    display: none;
  }
  .about {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .about__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 60%;
      padding: 105px 50px 0px 0px;
    }
    .about__containerTitle {
      display: flex;
      justify-content: space-between;
    }
    .about__text {
      display: flex;
      font-weight: 600;
      font-size: 30px;
      line-height: 35px;
    }
    .about__textSecond {
      padding: 20px 0px 0px 0px;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
    }
    .about__icons {
      padding-top: 77px;
      display: flex;
      gap: 45px;
      justify-content: flex-start;
    }
    .about__iconsItems {
      display: flex;
      align-items: flex-start;
      gap: 60px;
      flex-direction: column;
    }
    .about__iconsTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }
    .about__iconsTextItem {
      font-size: 16px;
      line-height: 20px;
    }
}
@media (max-width: 1039px) {
    .about__containerIcons,
    .about__icons_430,
    .about__text_430 {
      display: none;
    }
    .about {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    
    .about__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 53%;
      padding: 85px 40px 0px 0px;
    }
    .about__containerTitle {
      display: flex;
      justify-content: space-between;
    }
    .about__text {
      display: flex;
      font-weight: 600;
      font-size: 24px;
      line-height: 35px;
    }
    .about__textSecond {
      padding: 40px 0px 0px 0px;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
    }
    .about__icons {
      padding-top: 60px;
      display: flex;
      padding-right: 145px;
      padding-left: 40px;
      gap: 30px;
    }
    .about__iconsItems {
      display: flex;
      align-items: flex-start;
      gap: 60px;
      flex-direction: column;
    }
    .about__iconsTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }
    .about__iconsTextItem {
      font-size: 16px;
      line-height: 20px;
    }
}
@media (max-width: 480px) {
    .about__icons,
    .about__text {
      display: none;
    }
    .about {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      .about__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        width: 90%;
      }
      .about__containerTitle {
        display: flex;
        flex-direction: column;
      }
      .about__text_430 {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
      .about__textSecond {
        width: 95%;
        padding-top: 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }
      .about__icons_430 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .about__iconsItems {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .about__iconsTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      }
      .about__iconsTextItem {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }
    .about__iconsItem_430 {
      height: 53px;
    }
    .about__iconsText {
      gap: 4px;
  }
}


