.treadmill_430px {
    display: flex;
    overflow: hidden;
    user-select: none;
    position: relative;
    padding-top: 100px;
  }
  .treadmill__container_430px {
      display: flex;
      background: #2689E9;
      gap: 30px;
      padding: 17px 12px;
      animation: scroll 50s linear infinite;
      animation-direction: reverse;
      justify-content: space-around;
  } 
@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
@media (max-width: 1124px) {
    .treadmill_430px {
        padding-top: 60px;
      }
}
@media (max-width: 480px) {
    .treadmill_430px {
    display: flex;
    padding-top: 182px;
    } 
    .treadmill_430px {
        padding-top: 0px;
      }
      .treadmill__container_430px {
        padding: 6px 0px;
        gap: 18px;
    } 
    .item_320px {
        width: 41px;
        height: 20px;
    }
    .itemLogo_320px {
        padding-top: 0px;
        width: 16px;
        height: 18px;
    }
}




