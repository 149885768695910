.footer__nav {
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* z-index: 10; */
}
/* .footer__containerView {
  z-index: 10;
} */
.footer__copyRight_actived {
  transition: all linear .1s;
  -webkit-transition: all linear .1s;
}
.footer__copyRight_actived:hover {
  color: #000000;
}
.footer__titleText {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #000000;
  /* z-index: 10; */
}
.footer__text {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000000;
  text-decoration: none;
}
.footer__text:hover {
  text-decoration: underline;
}
.footer__containerRightContentTitle {
display: flex;
align-items: center;
justify-content: center;
padding-bottom: 20px;
text-decoration: none;
color: #000000;
}
.footer__rightContent {
background-color: #fff;
width: auto;
padding-left: 10px;
padding-right: 10px;
padding-top: 50px;
padding-bottom: 30px;
}
.footer__rightContentTitle_430 {
text-align: center;
font-size: 14px;
font-weight: 700;
line-height: 21px;
color: #000000;
}
.footer__containerRightContentDate {
display: flex;
justify-content: flex-end;
padding-bottom: 10px;
}
.footer__rightContentDate {
font-size: 14px;
font-weight: 500;
line-height: 21px;
}
.footer__containerRightContentText {
display: flex;
justify-content: flex-start;
}
.footer__rightContentText {
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: -0.011em;
text-align: left;
}
.footer__rightContentTextThumbnail {
font-size: 14px;
font-weight: 600;
line-height: 21px;
}
.footer__copyRight {
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 18px;
color: #00000080;
text-decoration: none;
}
@media (min-width: 1154px) {
.footer__containerViewForEdites_430,
.footer__container_430,
.footer__logo_430,
.footer__icons_430,
.footer__policy_430,
.footer-media_hide,
.footer-media,
.footer__containerPolicy_430 {
  display: none;
}
.footer__sidePadding {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer__containerView {
  display: flex;
  justify-content: space-between;
  padding-left: 40px; 
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 35px;
  /* z-index: 5; */
}
.footer__rights {
  display: flex;
  gap: 100px;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
}
.footer__contactsContainer {
    display: flex;
    gap: 56px;
  }
  .footer__contactsContainer_1440 {
    display: flex;
  }
  .footer__contacts {
    display: flex;
    gap: 150px;
    justify-content: space-between;
    flex-wrap: wrap;
    text-decoration: none;
  }
  .footer__contacts_430 {
    display: flex;
    flex-direction:column;
    gap: 37px;
  }
  .footer__contacts_1024 {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
  .footer__requisites {
    display: flex;
    gap: 60px;
  }
  .footer__navContent {
    display: flex;
    gap: 30px;
  }
  .footer__navContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .footer__navContainer_430 {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .footer__navContainer_hours {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .footer__policy {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
  }
  .footer__titleText_430 {
    display: flex;
    flex-direction: column;
  }
  .footer__text_withoutDecoration {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #000000;
    text-decoration: none;
  }
  .footer__icons {
    display: flex;
    padding-top: 90px;
    padding-left: 50px;
    gap: 15px;
  }
  .footer__containerCopyRight {
    display: flex;
  } 
}    
@media (max-width: 1153px) {
  .footer__containerViewForEdites_430,
  .footer__container_430,
  .footer__logo_430,
  .footer__icons_430,
  .footer__policy_430,
  .footer-media_hide,
  .footer-media,
  .footer__navContainer_hide,
  .footer__containerPolicy_430 {
    display: none;
  }
  .footer__containerView {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    padding-left: 40px; 
    padding-right: 40px;
    padding-top: 30px;
  }
  .footer__rights {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
}
  .footer__contactsContainer {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    .footer__contacts {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .footer__contacts_430 {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    .footer__contacts_1024 {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .footer__requisites {
      display: flex;
      gap: 60px;
    }
    .footer__navContent {
      display: flex;
      gap: 30px;
    }
    .footer__navContainer {
      display: flex;
      gap: 30px;
    }
    .footer__navContainer_430 {
      display: flex;
      gap: 60px;
    }
    .footer__navContainer_hours {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
    .footer__policy {
      display: flex;
      gap: 30px;
      justify-content: flex-start;
      align-items: flex-end;
    }
    .footer__titleText_430 {
      display: flex;
      flex-direction: column;
    }
    .footer__text_withoutDecoration {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #000000;
      text-decoration: none;
    }
    .footer__icons {
      display: flex;
      padding-top: 90px;
      padding-left: 50px;
      gap: 15px;
    }
    .footer__containerCopyRight {
      display: flex;
      flex-direction: column;
    }
}
@media (max-width: 480px) {
  .footer__containerView,
  /* .footer__navContainer_hide, */
  .footer__logo,
  .footer__icons,
  .footer__policy {
    display: none;
  }
  .footer__workingHours {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .footer__sidePadding {
    padding-left: 20px;
    padding-bottom: 15px;
  }
  /* .footer__nav {
    gap: 3px;
    flex-direction: row;
  } */
  .footer__containerViewForEdites_430 {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
    .footer__rights {
      display: flex;
      gap: 97px;
      flex-direction: row;
      justify-content: flex-start;
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 0px;
      padding-right: 0px;
      justify-content: center;
  }
  .footer__containerView_430 {
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-top: 20px;
  }
    .footer__contactsContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .footer__contactsContainer_430 {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    .footer__contactsContainerForEdites_430 {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .footer__contactsForEdites_430 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      gap: 45px;
    }
    .footer__iconsForEdites_430 {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      gap: 10px;
      padding-right: 16px;
    }
    .footer__containerViewForEdites_430 {
      background-color: #fff;
      width: auto;
      border-radius: 30px;
      }
    .footer__logo_430 {
      display: flex;
      padding: 20px;
      padding-bottom: 15px;
    }
    .footer__contacts {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    .footer__contacts_430 {
      display: flex;
      flex-direction: row-reverse;
      gap: 30px;
    }
    .footer__navContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .footer__navContainer_hours {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding-left: 20px;
    }
    .footer__navContainer_430 {
      display: flex;
      padding-left: 20px;
      gap: 17px;
    }
     .footer__navContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .footer__navContainer_430 {
      display: flex;
      padding-left: 20px;
      gap: 10px;
      flex-direction: column;
    }
    .footer__icons_430 {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
    }
    .footer__policy_430 {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-decoration: underline;
      color: #32323299;
    }
    .active {
      color: #323232;
    }
    .footer__containerPolicy_430 {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-left: 20px;
      padding-bottom: 30px;
    }
    .footer__text_withoutDecoration {
      display: inline-block;
      /* width: 95%; */
      word-wrap: break-word;
      -ms-word-wrap: break-word;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-decoration: none;
      /* max-width: 70%; */
      word-wrap: break-word;
    }
    .footer__text_430 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #000000;
      text-decoration: none;
      padding-left: 12px;
    }
    .footer__nav_430 {
      display: flex;
      flex-direction: column;
      padding-left: 25px;
    }
    .footer__titleText_430 {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 5px;
      /* padding-left: 45px; */
    }
    .footer__policy_430 {
      display: flex;
      padding-top: 5px;
      padding-left: 20px;
      padding-bottom: 30px;
      flex-direction: column;
    }
    .footer__copyRight_actived{
      color: #000000;
  }
  .footer__copyRight{
    display: flex;
    flex-wrap: nowrap;
}
  .footer__containerCopyRight {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
}
