.benefits__saving {
    border-radius: 30px;
    background: #FFFFFF;
    padding: 17px 20px;
}
@media (min-width: 1025px) {
  .benefits__container {
      display: flex;
      flex-direction: column;
      padding-top: 60px;
      padding-left: 40px;
      padding-right: 40px;
      gap: 30px;
    }
    .benefits__content {
      display: flex;
      align-items: center;
      gap: 22px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #000000;
    }
  }
@media (max-width: 1024px) {
    .benefits__container {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        gap: 30px;
      }
      .benefits__content {
        display: flex;
        align-items: center;
        gap: 22px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
      }
    }
@media (max-width: 480px) {
    .benefits {
      width: 95%;
    }
    .benefits__container {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        gap: 14px;
        /* padding-bottom: 35px; */
      }
      .benefits__content {
        display: flex;
        align-items: center;
        gap: 22px;
        line-height: 20px;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
}