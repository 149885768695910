.header__titleContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.header__title {
    display: flex;
    flex-wrap: nowrap;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: #32323299;
    letter-spacing: -0.011em;
    line-height: 0.5;
}
.header__logo {
    display: flex;
    flex-wrap: wrap;
    gap: 55px;
    align-items: center;
}
.header__separator {
    height: 70px;
    border-left: 1px solid #7D7D7D99
}
.header__info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.header__links {
    text-decoration: none;
    color: #323232;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
}
.header__links:hover {
    font-weight: 600;
    color: #000000;
}
.header__links:active {
    font-weight: 500;
}
.header__containerOfButton {
    text-decoration: none;
}
.header__button {
    padding: 15px 30px;
    color: #2689E9;
    border-radius: 100px;
    border: solid 2px #2689E9;
    font-weight: 700;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
}
.header__button:hover {
    background: #2689E9;
    color: white;
} 
.header__button:active {
    color: #2689E9;
    background: none;
}
@media (min-width: 1150px) {
    .header__logoOfHeader_430,
    .header__logo430px,
    .header__burger,
    .header__links_430px,
    .header__cross_430px,
    .header__container_430,
    .header__icons_430, 
    .header430px {
      display: none;
    }
    .header__accreditation {
      padding-bottom: 5px;
    }
    .header__ContainerOfAccreditation {
      display: flex;
      align-items: center;
      gap: 16.5px;
    }
    .header__titleOfAccreditation {
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.011em;
      color: #32323299;
    }
    .header__linksOfHeader {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 20px;
  }
    .header__linksOfHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}
    .header__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 35px;
    }
    .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 40px;
  }
}
@media (max-width: 1149px) {
  .header__containerAccreditation,
  .header__logoOfHeader_430,
  .header__logo430px,
  .header__burger,
  .header__links_430px,
  .header__cross_430px,
  .header__container_430,
  .header__icons_430, 
  .header430px {
    display: none;
  }
  .header__accreditation {
    padding-bottom: 5px;
  }
  .header__ContainerOfAccreditation {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .header__titleOfAccreditation {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.011em;
    color: #32323299;
  }
  .header__linksOfHeader {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
}
  .header__breadcrumbs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
  }
  .header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
}
}
@media (max-width: 480px) {
    .header__linksOfHeader,
    .header__links,
    .header,
    .header-media_hide,
    .header__button,
    .header__accreditation,
    .header__title,
    .header__separator,
    .header__links,
    .header__container,
    .header__logoGrey {
      display: none;
    }
    .header__breadcrumbs,
    .header__logo430px,
    .header__burger,
    .header__container_430 {
      display: flex;
    }
    .header__logo430px {
      padding-left: 0px;
    }
    .header__links_430px {
        display: flex;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.5px;
        color: #323232;
      }
      .header__cross_430px {
        display: flex;
        padding-right: 25px;
      }
      .header-media {
        display: inline-block;}
        /* transition: transform .3s;
        transform: translate(0, -110%);
        animation: position_hidden 0.00000000000000000001s .3s forwards;
    }
    @keyframes position_hidden {
      0% {
        position: static;
      }
      99% {
        position: static;
      }
      100% {
        position: absolute;
        top: -100%;
      }
    }
      .header-media_hide {
        transition: transform .3s;
    transform: translate(0, 0px);
    margin-top: 0;
      } */
      /* .header-media_hide {
        transition: transform .3s;
        transform: translate(0, -110%);
        animation: position_hidden 0.00000000000000000001s .3s forwards;
      } */
      .header__burgerHide_430px {
        z-index: 20;
        position: absolute;
        top: 0px;
        right: 0px;
        background: #FFFFFF;
        width: 100%;
        border-radius: 0px 0px 30px 30px;
        position: absolute;
        animation: mymove 300ms;
        animation-timing-function: ease-in;
      }
      @keyframes mymove {
        0% {top: -480px;}
        100% {top: 0px;}
      } 
      .header__containerLinksOfHeader_430px {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        padding-left: 20px;
      }
      .header__linksOfHeader_430px {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
      .header__containerLogoOfHeader_430px {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        padding-top: 37px;
        padding-bottom: 20px;
      }
      .header__logoOfHeader_430 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 17px;
      }
      .header__titleOfLogo_430 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      .header__ContainerOfAccreditation_430 {
        display: flex;
        gap: 11px;
      }
      .header__titleOfAccreditation_430 {
        font-size: 8px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.011em;
      }
      .header430px {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 84px;
        border-radius: 30px;
        /* background-color: #FFFFFF; */
      }
      .header430px__buttons {
        display: flex;
        gap: 22px;
        align-items: center;
        justify-content: center;
      }
      .header__container_430 {
        /* padding-top: 15px; */
      }
      .header__burger {
        padding-right: 0px;
      }
      .header__icons_430 {
        display: flex;
        justify-content: flex-start;
        padding-left: 20px;
        padding-top: 20px;
        gap: 16px;
      }
}
