.vacation__choiceContent {
    position: relative;
}

@media (min-width: 431px) {
    .choiceContact-media_hide {
      display: none;
    }
    .choiceContact-media {
        display: flex;
    }
    .choiceContact__container {
        background-color:#FFFFFF;
        border-radius: 30px;
        width: 40%;
        z-index: 3;
        top: 0px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        /* animation: mymove 300ms;
        animation-timing-function: ease-in; */
      }
      /* @keyframes mymove {
        from {bottom: -580px;}
        to {bottom: 50px;}
      } */
    .choiceContact__cross {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        padding-right: 30px;
    }
    .choiceContact__message {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
    .choiceContact__containerMessage {
        border-radius: 30px;
        background: #F1F1F1;
        padding: 36px 30px;
        display: flex;
        justify-content: center;
    }
    .choiceContact__text {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        text-align: center;
    }
    .choiceContent__logoOfContact {
        display: flex;
        flex-direction: column;
        gap: 41px;
        padding-top: 46px;
        justify-content: center;
        align-items: center;
        padding-bottom: 58px;
    }
    .choiceContent__textOfContact {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
    }
    .choiceContent__items {
        display: flex;
        align-items: center;
        gap: 26px;
        text-decoration: none;
        color: #323232
    }
}
@media (max-width: 480px) {
    .choiceContact-media_hide {
      display: none;
    }
    .choiceContact-media {
        display: flex;
    }
    .choiceContact__container {
        background-color:#FFFFFF;
        border-radius: 30px;
        width: 100%;
        z-index: 3;
        top: 0px;
        position: fixed;
        top: 50px;
        animation: mymove 300ms;
        animation-timing-function: ease-in;
      }
      @keyframes mymove {
        from {bottom: -580px;}
        to {bottom: 50px;}
      }
    .choiceContact__cross {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        padding-right: 30px;
    }
    .choiceContact__message {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
    .choiceContact__containerMessage {
        border-radius: 30px;
        background: #F1F1F1;
        padding: 36px 30px;
        display: flex;
        justify-content: center;
    }
    .choiceContact__text {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        text-align: center;
    }
    .choiceContent__logoOfContact {
        display: flex;
        flex-direction: column;
        gap: 41px;
        padding-top: 46px;
        justify-content: center;
        align-items: center;
        padding-bottom: 58px;
    }
    .choiceContent__textOfContact {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
    }
    .choiceContent__items {
        display: flex;
        align-items: center;
        gap: 26px;
        text-decoration: none;
        color: #323232
    }
}