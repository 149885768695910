.vacation__container {  
    border-radius: 30px;
    background: linear-gradient(12deg, #147EE5 16.82%, #94CAFF 98.22%);
    width: 32%;
    height: 365px;
}
.vacation__less {
    transition: transform .3s;
    transform: translate(0, 0px);
    margin-top: 0;
    position: relative;
    background-color: #418DE7;
    /* background-color: #FFFFFF; */
    border-radius: 0 0 30px 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-top: 12px;
    /* width: 94%; */
}
.vacation__more {
    display: flex;
    flex-direction: row;
    transition: transform .3s;
    transform: translate(0, -100%);
    animation: position_hidden 0.00000000000000000001s .3s forwards;
}

@keyframes position_hidden {
  0% {
    position: static;
  }
  99% {
    position: static;
  }
  100% {
    position: absolute;
    top: -100%;
  }
}
 .vacation__moreSymbolTrue {
    display: inline-block;
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: translateX(0%) rotate(90deg);
    transition-duration: 0.5s;
 } 
 .vacation__moreSymbolFalse {
    display: inline-block;
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: translateX(0%) rotate(0deg);
    transition-duration: 0.5s;
 }
.vacation__more_430
.vacation__infoImg {
    display: flex;
    gap: 12px;
}
.vacation__buttonOfContact {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 5px;
    background: #2689E9;
    width: 118px;
    height: 40.29268264770508px;
    border-radius: 28px;
    line-height: 35px;
    font-size: 10px;
    font-weight: 600;
    line-height: 35px;
    color: #F4F4F4;
    
}
.vacation__applicationContainer {
    padding-top: 5px;
    padding-left: 40px;
}
.vacation__application {
    width: 97%;
    border-radius: 30px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vacation__applicationContent {
    padding: 32px 30px;
    display: flex;
    align-items: center;
    gap: 22px;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;  
}
.vacation__button {
    padding-right: 30px;
}
/* .vacation__buttonOfContact_animate {
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
} */
@media (min-width: 1130px) {
  .vacation__infoImg_1024,
  .vacation__image_1024,
  .vacation-media_hide,
  .vacation-media,
  .vacation_430,
  .vacation__button_430
  {
    display: none;
  }
  .vacation {
      display: flex;
      gap: 25px;
      flex-direction: column;
    }    
  .vacation__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
  }
  .vacation__containerInfo {
    padding: 40px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.vacation__containerItem {
  padding-top: 65px;
  width: 100%;
}
.vacation__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 20px;
}
.vacation__infoImg {
  display: flex;
  align-items: center;
  gap: 12px;
}
  .vacation__content {
    display: flex;
    gap: 40px;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    height: 422px;
  }
  .vacation__location {
    padding-top: 65px;
    display: flex;
    justify-content: space-between;
}
  }
@media (max-width: 1129px) {
    .vacation__infoImg_1440,
    .vacation__image,
    .vacation-media_hide,
    .vacation-media,
    .vacation_430,
    .vacation__button_430
    {
      display: none;
    }
    .vacation {
        display: flex;
        gap: 25px;
        flex-direction: column;
      } 
    .vacation__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }
    .vacation__containerInfo {
      padding: 30px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      background: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
  }
  .vacation__infoImg {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .vacation__containerItem {
      padding-top: 45px;
      width: 100%;
  }
  .vacation__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
}
    .vacation__content {
      display: flex;
      gap: 30px;
      padding-top: 30px;
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
  }
  .vacation__location {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
    }
@media (max-width: 480px) {
    .vacation,
    .vacation-media_hide {
        display: none;
    }
    .vacation430 {
      overflow: hidden;
    }
    .vacation_430 {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
      }
      .vacation__container_430 {
        position: relative;
        border-radius: 30px;
        width: 100%;
        z-index: 1;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px #E4E4E4;
        /* background: linear-gradient(12deg, #147EE5 16.82%, #94CAFF 98.22%); */
      }
      .vacation__more {
        position: relative;
        background-color: #418DE7;
        border-radius: 0px 0px 30px 30px;
        padding-left: 25px;
        padding-bottom: 20px;
        padding-top: 12px;
        /* width: 94%; */
      }
      .vacation__moreBack {
        position: absolute;
        top: -20px;
        right: 0px;
        background-color: #418DE7;
        width: 100%;
        height: 25px;
      }
      .vacation__moreContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
      }
      .vacation__moreItem {
        display: flex;
        gap: 12px;
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 550;
        line-height: 20px;
        align-items: flex-start;
      }
      .vacation__title_430 {
        padding-top: 20px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 35px;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: #323232;
        /* color: #F4F4F4; */
      }
      .vacation__content {
        display: flex;
        gap: 40px;
        padding-top: 30px;
        padding-left: 40px;
        padding-right: 40px;
        height: 422px;
    }
      .vacation__infoImg_430 {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
      .vacation__more_430 {
        display: flex;
        gap: 5px;
        padding-top: 10px;
        padding-left: 20px;
        padding-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #323232;
        /* color: #F4F4F4; */
        cursor: pointer;
      }
      .vacation__content_430 {
        position: relative;
        display: flex;
        justify-content: space-between;
      }
      .vacation__image_430 {
        padding-left: 62px;
        position: absolute;
        /* bottom: -51px; */
        top: -30px;
        right: 0px;
        z-index: -1;
      }
      .vacation__containerButton_430 {
        padding: 20px 20px;
        padding-bottom: 20px;
      }
      .vacation__button_430 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        padding: 12px 35px;
        border-radius: 30px;
        gap: 10px;
        background: #2689E9;
        color: #F4F4F4;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
      }
}
