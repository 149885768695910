.mainPrint {
  background-image: url(../../public/images/Main_print_aron-yigin-dFnEgqVYMTw-unsplash.jpg);
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position-y: y 1%;
  background-size: cover;
}
.mainPrint__snake {
  background-color: coral;
}
.mainView__button {
transition: all linear .3s;
-webkit-transition: all linear .3s;
}
.mainView__containerButtonOfContact_isHover {
  background: linear-gradient(267.72deg, #0F74D7 16.06%, #42A1FF 89.42%);
}
.mainView__buttonOfContact {
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  color: #F4F4F4;
}
.mainView__languageSelect {
overflow: hidden;
  border: 1px solid #2689E9;
  outline: 0.01px solid #2689E9E3;
  border-radius: 28px;
  font-family: Montserrat;
  /* color: #00000099; */
  color: #323232;
  font-size: 16px;
  font-weight: 700;
  width: 85px;
  height: 45px;
  padding-left: 20px;
  letter-spacing: inherit;
  word-spacing: inherit;
  appearance: none;
  background: url(../../public/images/caret.svg);
  background-repeat: no-repeat;
  background-position: 52px 17px;
}
.mainView__languageSelect::-ms-expand {
display: none;
}
/* .mainView__languageSelect > option {
background-color: transparent;
border: 1px solid #2689E9;
outline: 0.01px solid #2689E9E3;
padding-right: 15px;
height: 75px;
width: 31px;
} */
.mainView__language {
display: flex;
gap: 20px;
padding-bottom: 20px;
}
.mainView__languageButton {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: Wadik;
  font-size: 16px;
  font-weight: 700;
  line-height: 39px;
  background: none;
  border: none;
  color: #2689E9;
  width: 51px;
  height: 31px;
  transition: all linear .3s;
  -webkit-transition: all linear .3s;
  border-radius: 16px;

}
.mainView__languageButton_actived {
background: #2689E9;
width: 51px;
height: 31px;
border-radius: 16px;
color: #FFFFFF;
}
.mainView__languageButton:hover {
  background: #2689E9;
  width: 51px;
  height: 31px;
  border-radius: 16px;
  color: #FFFFFF;
}
.mainView__languageButton:active {
border: 1px solid #2689E9;
background: none;
width: 51px;
height: 31px;
border-radius: 16px;
color: #2689E9;
}
  @media (min-width: 1150px) {
    .mainView__Icons_1024,
    .mainPrint430px,
    .mainView__containerButton_430px, 
    .mainView__button_430px,
    .mainPrint__containerName430px, 
    .mainPrint__name430px_containerSpan, 
    .mainPrint__name430px,
    .mainPrint__second_430px,
    .mainPrint__image_430px,
    .mainView__image_430px {
        display: none;
    }
    .mainPrint__name {
      padding-top: 90px;
      padding-left: 50px;
      padding-right: 50px;
      color: #2689E9;
      font-size: 34px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: -0.011em;
  }
  .mainPrint__second {
    padding-left: 53px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    line-height: 1.5;
    padding-top: 26px;
    margin: 0px;
}
.mainPrint__second_highlight {
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 700;
}
.mainPrint__text_withoutDecoration {
color: #000000;
text-decoration: none;
cursor: text;
}
    .mainView__contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      padding-top: 20px;
  }
  .mainView__containerContactButtons {
    display: flex;
    justify-content: space-between;
  }
    .mainView__containerButton {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* padding-top: 66px; */
        padding-left: 50px;
        padding-right: 50px;
    }
    .mainView__containerMainButton {
      padding-top: 66px;
    }
      .mainView__button {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 5px 10px;
        background: #2689E9;
        color: white;
        border: solid 1px #2689E9;
        border-radius: 100px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        width: 210px;
        height: 44px;
    }
    .mainView__button:hover {
      background: none;
      color: #2689E9;
      border: solid 1px #2689E9;
    }
    .mainView__button:active {
      background: #2689E9;
      color: white;
    }
    .mainView__icons {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-right: 10px;
      padding-top: 25px;
  }
    .mainView__containerButtonOfContact {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 174px;
      height: 42px;
      padding: 10px 1px 10px 10px;
      border-radius: 31px;
      gap: 11px;
      background: #2689E9;
      
    }
    .mainPrint__title {
      display: flex;
      flex-wrap: nowrap;
      padding-top: 100px;
      padding-left: 50px;
      color: #2689E9;
      font-size: 48px;
      font-weight: 700;
    }
    .mainView__containerIcons {
      display: flex;
      justify-content: space-between;
    } 
    .mainView__containerLanguage {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 30px;
    }
    }
  @media (max-width: 1149px) {
    .mainPrint430px,
    .mainView__containerButton_430px, 
    .mainView__button_430px,
    .mainPrint__containerName430px, 
    .mainPrint__name430px_containerSpan, 
    .mainPrint__name430px,
    .mainPrint__second_430px,
    .mainPrint__image_430px,
    .mainView__image_430px {
        display: none;
    }
    .mainPrint__name {
      padding-top: 90px;
      padding-left: 40px;
      padding-right: 40px;
      color: #2689E9;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: -0.011em;
      line-height: 39px;
  }
  .mainView__icons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 10px;
    padding-top: 25px;
}
    .mainPrint__second {
      padding-left: 43px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      line-height: 1.5;
      padding-top: 26px;
      margin: 0px;
  }
  .mainPrint__second_highlight {
    padding-left: 3px;
    padding-right: 3px;
    font-weight: 700;
}
    .mainView__contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      padding-top: 20px;
  }
  .mainView__containerContactButtons {
    display: flex;
    justify-content: space-between;
  }
    .mainView__containerButton {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        /* padding-top: 66px; */
        padding-left: 40px;
        padding-right: 40px;
    }
    .mainView__containerMainButton {
      padding-top: 66px;
    }
      .mainView__button {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 5px 10px;
        background: #2689E9;
        color: white;
        border: solid 1px #2689E9;
        border-radius: 100px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        width: 210px;
        height: 44px;
    }
    .mainView__button:hover {
      background: none;
      color: #2689E9;
      border: solid 1px #2689E9;
    }
    .mainView__button:active {
      background: #2689E9;
      color: white;
    }
    .mainView__containerButtonOfContact {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 174px;
      height: 42px;
      padding: 10px 1px 10px 10px;
      border-radius: 31px;
      gap: 11px;
      background: #2689E9;
    }
    .mainPrint__title {
      display: flex;
      flex-wrap: nowrap;
      padding-top: 80px;
      padding-left: 40px;
      color: #2689E9;
      font-size: 48px;
      font-weight: 700;
    }
    .mainView__containerIcons {
      display: flex;
      justify-content: space-between;
    } 
    .mainView__Icons_1024 {
      /* padding-top: 60px; */
    }
    .mainView__containerLanguage {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 15px;
      /* padding-top: 38px; */
    }
    }
  @media (max-width: 480px) {
      .mainView__Icons_1024,
      .mainView__icons,
      .mainPrint,
      .mainPrint__second,
      .mainPrint__name,
      .mainView__containerButton_directionOfContact,
      .mainView__button,
      .mainView__containerButtonOfContact {
        display: none;
      }
      .mainPrint430px {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .mainView__containerMainButton {
        padding-top: 0px;
      }
      .mainView__containerButton {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding-right: 0px;
          padding-left: 0px;
          padding-top: 155px;
          gap: 0px;
        }
        .mainView__image_430px {
          display: flex;
        }
        .mainView__button {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 5px 10px;
          background: #2689E9;
          color: white;
          border: solid 1px #2689E9;
          border-radius: 100px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          width: 184px;
          height: 42px;
        }
        .mainPrint__containerName430px{
          display: flex;
          flex-direction: column;
          /* height: 238px; */
          border-radius: 30px;
          /* background-color: #FFFFFF; */
          /* padding-top: 20px; */
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 10px;
          gap: 20px;
        }
        .mainPrint__name430px {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
        .mainPrint__name430px_h1 {
          /* display: inline-block;
          word-wrap: break-word;
          -ms-word-wrap: break-word; */
          text-align: center;
          margin: 0;
          color: #2689E9;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: 0.03em;
        }
        .mainPrint__name430px_containerSpan {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }
        .mainPrint__name430px_span {
          color: #2689E9;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.03em;
          text-align: center;
        }
        .mainPrint__name430px_span2 {
          color: #2689E9;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.03em;
          text-align: center;
        }
        .mainPrint__container_430px{
          display: flex;
          flex-direction: column;
          height: 238px;
          border-radius: 30px;
          background-color: #FFFFFF;
          }
        .mainPrint__containerSecond_430px {
          display: flex;
          flex-direction: column;
        }
        .mainPrint__second_430px{
          display: inline-block;
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          padding-top: 20px;
          padding-bottom: 28px;
          word-wrap: break-word;
          -ms-word-wrap: break-word;
        }
        .mainPrint__second_highlight {
          padding-left: 3px;
          padding-right: 3px;
          font-weight: 700;
      }
        .mainPrint__title {
          display: inline-block;
          width: 100%;
          word-wrap: break-word;
          -ms-word-wrap: break-word;
          font-size: 26px;
          font-weight: 700;
          padding-top: 25px;
          padding-left: 20px;
          color: #2689E9;
          line-height: 24px;
        }
        .mainPrint__image_430px {
          display: flex;
          width: 100%;
        }
  }