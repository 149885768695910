@import url('./about.css');
@import url('./application.css');
@import url('./benefits.css');
@import url('./choiceContact.css');
@import url('./footer.css');
@import url('./formApplication.css');
@import url('./formVidjet.css');
@import url('./Header.css');
@import url('./MainView.css');
@import url('./services.css');
@import url('./thankYouPage.css');
@import url('./treadmill.css');
@import url('./vacations.css');

body {
  margin: 0;
  font-family: Montserrat;
  font-weight: 550;
  color: #323232;
  background: #f4f4f4;
  /* background: coral; */
  width: 100%;
}
@font-face {
  font-family: Wadik;
  src: url(../../public/fonts/Wadik.otf); 
}
@font-face {
  font-family: Montserrat;
  src: url(../../public/fonts/static/Montserrat-Medium.ttf); 
}
@font-face {
  font-family: Inter;
  src: url(../../public/fonts/static/Inter-Medium.ttf); 
}
.mainPrint__name430px, 
.mainPrint__title, 
.mainPrint__name, 
.formApplication__mainTitle {
  font-family: Wadik;
  margin: 0;
}
/* .advent { animation: show 2s ease-in-out;
}
.block {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.block-show {
  opacity: 1;
}
@keyframes show {
  from {opacity: 0;} to {opacity: 1;}
  }  
} */
@media (max-width: 480px) {
  body {
    /* max-width: 430px; */
    margin: 0 auto;
    /* overflow-x: hidden; / */
    /* overflow-y: scroll; */
    width: 100%;
  }
  .containerviewPort_430px{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .containerviewPort_430px,
  .benefits__container {
    padding-right: 20px;
  }
  .containerviewPort_430px,
  .mainPrint__second_430px,
  .benefits__container,
  .header__logo430px
  {
    padding-left: 20px;
  }
  .container_430 {
    padding-top: 20px;
  }
.formApplication,
.services__container,
.vacation_430 {
  padding-left: 20px;
  padding-right: 20px;
}
}


